






































































































































import { Component, Vue } from 'vue-property-decorator';
import { Ax } from '@/utils';
import { store } from '../common';
import { ListItem, ListItemPart, Overlay } from '../components';
import I18n from '../I18n';
import { SingleOrgField } from '../organizations';
import { Org } from '../types';


const i18n = new I18n('modules.budget.staffing_table.versions');

interface Version {
    id: number;
    year: number;
    title: string;
    newTitle?: string;  // Присваиваем новое имя заголовка
}


@Component({
    components: {
        ListItem,
        ListItemPart,
        Overlay,
        SingleOrgField,
    },
})
export default class Page extends Vue {
    // region Lifecycle
    private created() {
        // region Org
        this.$watch('org', (org: Org | null) => {
            store.org = org;
            this.selectedVersion = null;
            this.resetVersions();
        });
        // endregion
    }

    private mounted() {
        this.resetVersions();
    }
    // endregion


    // region Utils
    private i18n = i18n;

    private get loading(): boolean {
        return this.versionsLoading;
    }

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
    // endregion


    // region Org
    private org: Org | null = store.org;
    // endregion


    // region Modal window - year and title for new version
    private paramsModalYear: number = 0;
    private paramsModalTitle: string = '';
    private paramsModalVisible = false;

    private onParamsModalOk() {
        this.continueVersionCreation();
    }
    // endregion


    // region Versions
    // noinspection JSMismatchedCollectionQueryUpdate
    private versions: Array<Version> = [];
    private versionsLoading = false;
    private selectedVersion: Version | null = null;
    private oldVersion: Version | null = null;

    private resetVersions() {
        if (this.org === null) {
            this.versions = [];
        } else {
            this.reloadVersions();
        }
    }

    private reloadVersions() {
        if (this.versionsLoading) {
            console.error('Cannot reload versions - another loading is running');
            return;
        }

        const org = this.org;
        if (org === null) {
            console.error('Cannot reload versions - organization is NULL');
            return;
        }

        this.versions = [];
        this.versionsLoading = true;
        this.selectedVersion = null;
        Ax<Array<Version>>(
            {
                url: `/api/budget/staffing_table/version/list?org-code=${encodeURIComponent(org.org.code)}`,
            },
            (data) => {
                this.versions = data;
            },
            (error) => {
                this.toast(
                    'danger',
                    this.i18n.translate('error.cannot_load_versions'),
                    error.toString(),
                )
            },
            () => {
                this.versionsLoading = false;
            },
        );
    }

    private createVersion(oldVersion?: Version) {
        this.oldVersion = oldVersion ?? null;
        this.paramsModalYear = oldVersion?.year ?? new Date().getFullYear();
        this.paramsModalTitle = oldVersion?.title ?? '';
        this.paramsModalVisible = true;
    }

    private continueVersionCreation() {
        if (this.versionsLoading) {
            console.error('Cannot create version - another loading is running');
            return;
        }

        const org = this.org;
        if (org === null) {
            console.error('Cannot create version - organization is NULL');
            return;
        }

        const year = this.paramsModalYear;
        const title = this.paramsModalTitle.trim();
        const oldVersion = this.oldVersion;

        let url = `/api/budget/staffing_table/version?org-code=${encodeURIComponent(org.org.code)}&year=${year}`;
        if (title.length > 0) {
            url += `&title=${encodeURIComponent(title)}`;
        }
        if (oldVersion !== null) {
            url += `&old-id=${oldVersion.id}`;
        }

        this.versionsLoading = true;
        Ax<Version>(
            {
                url: url,
                method: 'POST',
            },
            (data) => {
                const id = data.id;
                const year = data.year;
                this.toast(
                    'success',
                    '',
                    this.i18n.translate('success.created', [id, year]),
                );
            },
            (error) => {
                this.toast(
                    'danger',
                    this.i18n.translate('error.cannot_create_new_version'),
                    error.toString(),
                )
            },
            () => {
                this.versionsLoading = false;
                this.resetVersions();
            },
        );
    }

    private onVersionClick(version: Version) {
        if (this.selectedVersion === version) {
            this.selectedVersion = null;
        } else {
            this.selectedVersion = version;
        }
    }

    private onCreateNewClick() {
        this.createVersion();
    }

    private onCopyClick() {
        const selectedVersion = this.selectedVersion;
        if (selectedVersion === null) {
            console.error('Cannot copy version - selected version is NULL');
            return;
        }

        this.createVersion(selectedVersion);
    }

    private onDeleteClick() {
        if (this.versionsLoading) {
            console.error('Cannot delete version - another loading is running');
            return;
        }

        const selectedVersion = this.selectedVersion;
        if (selectedVersion === null) {
            console.error('Cannot delete version - selected version is NULL');
            return;
        }
        const id = selectedVersion.id;

        this.versionsLoading = true;
        Ax(
            {
                url: `/api/budget/staffing_table/version?id=${id}`,
                method: 'DELETE',
            },
            () => {
                this.toast(
                    'success',
                    '',
                    this.i18n.translate('success.deleted', [id]),
                )
            },
            (error) => {
                this.toast(
                    'danger',
                    this.i18n.translate('error.cannot_delete_version', [id]),
                    error.toString(),
                );
            },
            () => {
                this.versionsLoading = false;
                this.resetVersions();
            },
        );
    }

    private setVersionName(version: Version) {
        const newName = version.newTitle ?? null
        const idVersion = version.id

        if (newName == null) {
            console.error('Cannot set version title  - newTitle is null');
            return;
        }
        Ax(
            {
                url: '/api/budget/staffing_table/version/newTitle',
                method: 'PUT',
                params: {
                    newTitle: newName,
                    idVersion: idVersion
                }
            },
            () => {
                version.title = newName
                this.toast(
                    'success',
                    '',
                    this.i18n.translate('success.saved', [newName]),
                )
            },
            (error) => {
                this.toast(
                    'danger',
                    this.i18n.translate('error.cannot_save_newTitle_version'),
                    error.toString(),
                )
            },
            () => {
                version.newTitle = ''
            },
        );
    }
    // endregion
}
